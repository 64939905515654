
<template>
  <common-trs-page-container>
    <template v-slot:page-content>
      <common-app-page-header
        :title="'Create User'"
      />
      <div class="trs-form-container-card">
        <users-form
          v-model="form"
        />
      </div>
    </template>
  </common-trs-page-container>
</template>

<script>
  import UsersForm from './Form'

  export default {
    name: 'UsersCreate',

    components: {
      UsersForm,
    },

    data: () => ({
      form: {},
    }),
  }
</script>
